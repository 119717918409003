/* sidebar-section */
.sidebar-section{
    width: 650px;
    height: 100vh;
    overflow-y: auto;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 1;
    display: flex;
    backdrop-filter: blur(8px);
    display: flex;
    align-items: center;
    justify-content: center;
}
.sidebar-content {
    text-align: center;
    height: 100vh;
    padding: 70px 100px 10px;
    /* display: flex;
    justify-content: center;
    flex-direction: column;  */
 }
.craftly-logo {
    padding-top: 1rem;
    margin-bottom: 2rem;
}


@media (max-width:1199px) {
    .sidebar-section{
        width: 600px;
    }
}

@media (max-width:991px){
    .sidebar-section{
        width: 550px;
        padding: 20px;
    }
}
@media (max-width:800px) {
    .craftly-logo {
        display: none;
    }
    .sidebar-content {
        text-align: center;
        height: auto;
        padding:0px
    }
}
@media (max-width:800px) {
    
    .sidebar-section {
        width: 100%;
        position: unset;
        height: 100%;
        border-radius: 50px 50px 0 0;
        background-color: white;
         
    }
}

   